.btn-group.movimento{
    display: block;
    width: 100%;

    label {
        display: block;
        width: 100%;
    }

    .btn-check:checked + .btn-outline-success,  .btn-check:checked + .btn-outline-danger{
        color: #fff;
        font-weight: bold;
    }
}

.bg-lightest{
    background-color: #efefef;
    border: 1px solid #ccc;
}
