/*!
 * bsStepper v{version} (https://github.com/Johann-S/bs-stepper)
 * Copyright 2018 - {year} Johann-S <johann.servoire@gmail.com>
 * Licensed under MIT (https://github.com/Johann-S/bs-stepper/blob/master/LICENSE)
 */

.bs-stepper {
    border: 1px solid #b1abab;
    border-radius: 5px;

    & .bs-title{
        font-variant: small-caps;
        padding: 0 10px;
        font-weight: bold;
        background-color: #cecece;
        border-bottom: 1px solid #b1abab;

        & p{
            margin: 0;
        }
    }


	.step-trigger {
		display: inline-flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		padding: 20px;
		font-size: 1rem;
		font-weight: 700;
		line-height: 1.5;
		color: #6c757d;
		text-align: center;
		text-decoration: none;
		white-space: nowrap;
		vertical-align: middle;
		user-select: none;
		background-color: transparent;
		border: none;
		border-radius: .25rem;
		transition: background-color .15s ease-out, color .15s ease-out;
		&:not(:disabled) {
			&:not(.disabled) {
				cursor: pointer;
			}
		}
		&:disabled {
			pointer-events: none;
			opacity: .65;
		}
		&:focus {
			color: #007bff;
			outline: none;
		}
		&:hover {
			text-decoration: none;
			background-color: rgba(0, 0, 0, .06);
		}
	}
	.step-trigger.disabled {
		pointer-events: none;
		opacity: .65;
	}
	.line {
		flex: 1 0 32px;
		min-width: 1px;
		min-height: 1px;
		margin: auto;
		background-color: rgba(0, 0, 0, .12);
	}
	.content {
		&:not(.fade) {
			display: none;
		}
	}
	.content.fade {
		visibility: hidden;
		transition-duration: .3s;
		transition-property: opacity;
	}
	.content.fade.active {
		visibility: visible;
		opacity: 1;
	}
	.content.active {
		&:not(.fade) {
			display: block;
			visibility: visible;
		}
	}
	.content.dstepper-block {
		display: block;
	}
	&:not(.vertical) {
		.bs-stepper-pane.dstepper-none {
			display: none;
		}
		.content.dstepper-none {
			display: none;
		}
	}
}

.bs-stepper-label {
	display: inline-block;
	margin: .25rem;
}

.bs-stepper-header {
	display: flex;
	align-items: center;
}

.bs-stepper-line {
	flex: 1 0 32px;
	min-width: 1px;
	min-height: 1px;
	margin: auto;
	background-color: rgba(0, 0, 0, .12);
}

.bs-stepper-circle {
	display: inline-flex;
	align-content: center;
	justify-content: center;
	width: 2em;
	height: 2em;
	padding: .5em 0;
	margin: .25rem;
	line-height: 1em;
	color: #fff;
	background-color: #6c757d;
	border-radius: 1em;
}

.active {
	.bs-stepper-circle {
		background-color: #007bff;
	}
}

.bs-stepper-content {
	padding: 0 20px 20px;
}

.bs-stepper.vertical {
	display: flex;
	.bs-stepper-header {
		flex-direction: column;
		align-items: stretch;
		margin: 0;
	}
	.bs-stepper-pane {
		display: block;
		&:not(.fade) {
			display: block;
			visibility: hidden;
		}
	}
	.content {
		display: block;
		&:not(.fade) {
			display: block;
			visibility: hidden;
		}
	}
}

.bs-stepper-pane {
	&:not(.fade) {
		display: none;
	}
}

.bs-stepper-pane.fade {
	visibility: hidden;
	transition-duration: .3s;
	transition-property: opacity;
}

.bs-stepper-pane.fade.active {
	visibility: visible;
	opacity: 1;
}

.bs-stepper-pane.active {
	&:not(.fade) {
		display: block;
		visibility: visible;
	}
}

.bs-stepper-pane.dstepper-block {
	display: block;
}

.vertical {
	.bs-stepper-pane.fade.dstepper-none {
		visibility: hidden;
	}
	.content.fade.dstepper-none {
		visibility: hidden;
	}
}

@media (max-width: 520px) {
	.bs-stepper {
		.step-trigger {
			flex-direction: column;
			padding: 10px;
		}
	}
	.bs-stepper-header {
		margin: 0 -10px;
		text-align: center;
	}
	.bs-stepper-content {
		padding: 0;
	}
}

@media (max-width: 400px) {
	.bs-stepper-line {
		flex-basis: 20px;
	}
	.bs-stepper {
		.line {
			flex-basis: 20px;
		}
	}
}
