.dados-bancarios{

}

.funcionalidade_label
{
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
}
