.projeto
{
    margin-top: 20px;

    & .accordion-button{
        color: #ffffff;
        margin-bottom: 0;
    }

    & div.titulo{
        font-size: small;
    }

    & .tipo_documento{
        & h5.titulo{
            color: #000;
            margin-bottom: 10px;
            padding: 5px;
        }

        & .modal-entrega{
            cursor: pointer;
        }

        & .estado{
            border: 1px solid #fff;
            border-radius: 10px;
            display: inline-block;
            margin-right: 5px;
            padding: 0px;
        }
    }
}

.modal-documento{
    .arquivo.border-aprovado {
        border-color: $success !important;
        border-width: 2px;
    }

    div.arquivo.border-rejeitado {
        border-color: $danger !important;
        border-width: 3px !important;
        border-style: dashed !important;
    }

    .area-upload{
        max-height: 325px;
        min-height: 50px;
        overflow-y: auto;
        transition: all 1s;

        &.flutuando{
            border: 4px dashed $orange-500 !important;
        }

        &.flutuando-hover{
            border: 4px dashed $blue-500 !important;
            background-color: $blue-100;
            max-height: 200px;
            min-height: 100px;
        }

        &.enviando{
            border: 4px dashed $teal-500 !important;
            background-color: $teal-100;
        }

        &.enviando::before{
            content: "Aguarde, carregando o envio...";
            color: #000;
            font-weight: bold;
        }

    }
}

.modal-loading {
    & .modal-content>div {
        display: none !important;
    }

    & .modal-content .loading {
        display: block !important;
        padding: 20px;
    }
}

.estado_text{
    text-transform: capitalize;
}

.agenda-click{
    cursor: pointer;
}
